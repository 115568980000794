    //Add Pause to the section (Dont work)
    var contentPause = "[break time='1s']";

    //Soundeffect (Dont work)
    var soundeffect = "[soundEffect::effect1]";
    var soundeffect = "<<soundSegment::effect1>>";

    //Add Parameter
    var addLocation = "{Ort}";
    var addAdress = "{Adresse}";
    var addTime = "{Zeit}";
    var addBranch = "{Filiale}";
    var addBrand = "{Marke}";
    
    /* Prosody Options
    * rate: "x-slow", "slow", "medium", "fast", "x-fast", or "default"
    * volume: +0db / -1db or "silent", "x-soft", "soft", "medium", "loud", "x-loud" or "default"
    * pitch: "x-low", "low", "medium", "high", "x-high", or "default"
    * */
    var addProSody = '[prosody volume="+0db" rate="medium" pitch="medium"]TEXT[/prosody]';


    //Creates the editor with custom toolbar
    tinymce.init({
      selector: 'textarea',
      height: 200,
      width: 600,
      menubar: false,
      toolbar: "undo redo | parameter pauseButton prosody",

    //   toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
      setup: (editor) => {
      editor.ui.registry.addButton('pauseButton', {
      text: 'Pause',
      tooltip: "Füge eine Pause ein",
      onAction: (_) => editor.insertContent(contentPause)  
    });

    editor.ui.registry.addButton('prosody', {
      text: 'Prosody',
      tooltip: "Ändere die Sprechgeschwindigkeit, Lautstärke und Tonhöhe eines Abschnittes",
      onAction: (_) => editor.insertContent(addProSody) 
    });

    editor.ui.registry.addMenuButton('parameter', {
      text: 'Parameter',
      tooltip: "Füge verschiedene Parameter für jede Audiospur hinzu",
      fetch: (callback) => {
        const items = [
          {
            type: 'menuitem',
            text: 'Ort',
            onAction: (_) => editor.insertContent(addLocation)
          },
          {
            type: 'menuitem',
            text: 'Adresse',
            onAction: (_) => editor.insertContent(addAdress)
          },
          {
            type: 'menuitem',
            text: 'Zeit',
            onAction: (_) => editor.insertContent(addTime)
          },
          {
            type: 'menuitem',
            text: 'Filiale',
            onAction: (_) => editor.insertContent(addBranch)
          },
          {
            type: 'menuitem',
            text: 'Marke',
            onAction: (_) => editor.insertContent(addBrand)
          },
        ];
        callback(items);
      }
    });
    

  }
});
  
